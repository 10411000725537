<template>
    <div>
        <v-sheet class="dense-inputs">
            <div class="d-flex flex-row align-center">
                <span class="font-weight-bold">{{ $t('message.productsList') }}</span>
                <v-btn class="ml-4" small @click="openProductSelector()">{{ $t('message.addProduct') }}</v-btn>
                <v-btn small icon @click="getProductList(); loading.reload = true" :loading="loading.reload" class="ml-3 mt-1"><v-icon>refresh</v-icon></v-btn>
                <v-switch
                    :label="$t('message.scrollingList')"
                    class="ml-4"
                    dense
                    hide-details
                    style="margin-top: 3px !important;"
                    v-model="scrollingTable"
                />
                <v-spacer/>
                <ExportTableJson
                    :export-conditions="{}"
                    :export-data="allProducts"
                    :export-fields="headers"
                    :export-source="'supplier-product-table'"
                    class="float-right"
                    style="padding-top: 0px !important;"
                />
            </div>
        </v-sheet>
        <div style="position: relative">
            <v-overlay
                :value="loading.products"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :fixed-header="scrollingTable"
                :headers="headers"
                :height="scrollingTable ? (allProducts.length > 10 ? scrollingTableHeight : 100) : undefined"
                :items-per-page="-1"
                :items="allProducts"
                :group-by="defaultGroupByProperty"
                class="mt-3 appic-table-light specification-table"
                dense
                hide-default-footer
                show-group-by
            >
                <template v-slot:group.header="{ group, groupBy, headers, isOpen, toggle }">
                    <td :colspan="headers.length">
                        <div>
                            <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                            {{ getHeader(groupBy) + ': ' + getPropertyValue(group) }}
                        </div>
                    </td>
                </template>
                <template v-slot:item.Product.id="{ item }">
                    <div class="text-center">
                        <v-menu>
                            <template v-slot:activator="{ on: menu }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.moreActions') }}</span>
                                </v-tooltip>
                            </template>
                            <v-list dense>
                                <v-list-item class="font-sm" @click="openProductChanger(item.Product.id, item.Product.product)"><v-icon small class="mr-1" color="black lighten-1">mdi-swap-horizontal-variant</v-icon>{{ $t('message.changeProduct') }}</v-list-item>
                                <v-list-item class="font-sm" @click="copyProduct(item.Product.id)"><v-icon small class="mr-1" color="black lighten-1">mdi-content-copy</v-icon>{{ $t('message.copyProduct') }}</v-list-item>
                                <v-list-item class="font-sm" @click="deleteProduct(item.Product.id, item.Product.product)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteProduct') }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:item.Product.design_profiles="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.designs"
                            :ref="'select_sp_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.design_profiles"
                            @blur="updateProductProperty({ref: 'select_sp_' + item.Product.id, id: item.Product.id, property: 'design_profiles', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'design_profiles',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.species="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.species"
                            :ref="'select_sp_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.species"
                            @blur="updateProductProperty({ref: 'select_sp_' + item.Product.id, id: item.Product.id, property: 'species', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'species',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.densities="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.densities"
                            :ref="'select_de_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.densities"
                            @blur="updateProductProperty({ref: 'select_de_' + item.Product.id, id: item.Product.id, property: 'densities', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'densities',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.compositions="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.compositions"
                            :ref="'select_co_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.compositions"
                            @blur="updateProductProperty({ref: 'select_co_' + item.Product.id, id: item.Product.id, property: 'compositions', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'compositions',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.glues="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.glues"
                            :ref="'select_gl_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.glues"
                            @blur="updateProductProperty({ref: 'select_gl_' + item.Product.id, id: item.Product.id, property: 'glues', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'glues',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.surfaces="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.surfaces"
                            :ref="'select_su_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.surfaces"
                            @blur="updateProductProperty({ref: 'select_su_' + item.Product.id, id: item.Product.id, property: 'surfaces', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'surfaces',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.finishings="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.finishings"
                            :ref="'select_fi_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.finishings"
                            @blur="updateProductProperty({ref: 'select_fi_' + item.Product.id, id: item.Product.id, property: 'finishings', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'finishings',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.grades="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.grades"
                            :ref="'select_gr_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.grades"
                            @blur="updateProductProperty({ref: 'select_gr_' + item.Product.id, id: item.Product.id, property: 'grades', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'grades',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.origin_certifications="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-90px">
                        <v-autocomplete
                            :items="propertyOptions.origin_certifications"
                            :ref="'select_oc_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.origin_certifications"
                            @blur="updateProductProperty({ref: 'select_oc_' + item.Product.id, id: item.Product.id, property: 'origin_certifications', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'origin_certifications',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.market_certifications="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-90px">
                        <v-autocomplete
                            :items="propertyOptions.market_certifications"
                            :ref="'select_mc_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.market_certifications"
                            @blur="updateProductProperty({ref: 'select_mc_' + item.Product.id, id: item.Product.id, property: 'market_certifications', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'market_certifications',id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.mcs="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-autocomplete
                            :items="propertyOptions.mcs"
                            :ref="'select_mo_' + item.Product.id"
                            chips
                            class="mb-1 super-dense pa-0"
                            deletable-chips
                            dense
                            hide-details="auto"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            v-model="item.Product.mcs"
                            @blur="updateProductProperty({ref: 'select_mo_' + item.Product.id, id: item.Product.id, property: 'mcs', value: $event})"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    :input-value="data.selected"
                                    class="my-1"
                                    close
                                    small
                                    v-bind="data.attrs"
                                    @click="data.select"
                                    @click:close="removeProductPropertyOption({item: data.item, property: 'mcs', id: item.Product.id})"
                                >
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </template>
                <template v-slot:item.Product.thicknesses="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-90px">
                    <v-lazy>
                        <v-textarea
                            :value="item.Product.thicknesses"
                            auto-grow
                            class="super-dense pa-0 mb-1"
                            clearable
                            dense
                            hide-details="auto"
                            outlined
                            rows="1"
                            @change="updateProductProperty({id: item.Product.id, property: 'thicknesses', value: $event})"
                            @click:clear="item.Product.thicknesses = null"
                        />
                    </v-lazy>
                    </div>
                </template>
                <template v-slot:item.Product.widths="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-90px">
                        <v-lazy>
                            <v-textarea
                                :value="item.Product.widths"
                                auto-grow
                                class="super-dense pa-0 mb-1"
                                clearable
                                dense
                                hide-details="auto"
                                outlined
                                rows="1"
                                @change="updateProductProperty({id: item.Product.id, property: 'widths', value: $event})"
                                @click:clear="item.Product.widths = null"
                            />
                        </v-lazy>
                    </div>
                </template>
                <template v-slot:item.Product.lengths="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-90px">
                        <v-lazy>
                            <v-textarea
                                :value="item.Product.lengths"
                                auto-grow
                                class="super-dense pa-0 mb-1"
                                clearable
                                dense
                                hide-details="auto"
                                outlined
                                rows="1"
                                @change="updateProductProperty({id: item.Product.id, property: 'lengths', value: $event})"
                                @click:clear="item.Product.lengths = null"
                            />
                        </v-lazy>
                    </div>
                </template>
                <template v-slot:item.Product.comments="{ item }">
                    <div class="d-flex flex-column justify-start fill-height max-width-140px">
                        <v-lazy>
                            <v-textarea
                                :value="item.Product.comments"
                                auto-grow
                                class="super-dense pa-0 mb-1"
                                clearable
                                dense
                                hide-details="auto"
                                outlined
                                rows="1"
                                @change="updateProductProperty({id: item.Product.id, property: 'comments', value: $event})"
                                @click:clear="item.Product.comments = null"
                            />
                        </v-lazy>
                    </div>
                </template>
            </v-data-table>
        </div>
        <ProductSelector
            :key="productSelectorKey"
            :customer-id="null"
            :supplier-id="supplierId"
            :open-dialog.sync="productSelector"
            @product-added="productAdded"
            @dialog-closed="productSelectorClosed"
        />
        <ChangeProduct
            :customer-id="null"
            :supplier-id="supplierId"
            :product-id="selectedProductId"
            :current-product="selectedProductGroup"
            :open-dialog.sync="changeProduct"
            @product-updated="productUpdated"
            @dialog-closed="changeProductClosed"
        />
    </div>
</template>

<script>
import {api} from "Api";
import {capitalize, isObject} from "Helpers/helpers";
const ChangeProduct = () => import("Components/Appic/ChangeProduct");
const ExportTableJson = () => import("Components/Appic/ExportTableJson.vue");
const ProductSelector = () => import("Components/Appic/ProductSelector")

export default {
    name: "ProductTableListV3",
    components: {
        ChangeProduct,
        ExportTableJson,
        ProductSelector
    },
    props: ['supplierId'],
    data() {
        return {
            addCommentKey: 1000,
            allProducts: [],
            defaultGroupByProperty: 'Product.product',
            defaultProduct: {
                Product: {
                    composition: [],
                    density: [],
                    design: [],
                    drying_type: [],
                    edge: [],
                    finishing: [],
                    glue: [],
                    grade: [],
                    grain: [],
                    id: 0,
                    length: [],
                    market_certification: [],
                    moisture_content: [],
                    origin_certification: [],
                    product: 'Decking',
                    profile: [],
                    sizes: [],
                    species: [],
                    surface: [],
                    thickness: [],
                    width: []
                }
            },
            groupByProperty: 'Product.product',
            loading: {
                products: false,
                reload: false
            },
            addComment: false,
            changeProduct: false,
            changeProductKey: 4000,
            productSelector: false,
            propertySelector: false,
            productSelectorKey: 2000,
            propertySelectorKey: 3000,

            propertyOptions: {
                designs: [],
                species: [],
                densities: [],
                compositions: [],
                glues: [],
                surfaces: [],
                finishings: [],
                grades: [],
                mcs: [],
                thicknesses: [],
                widths: [],
                lengths: [],
                market_certifications: [],
                origin_certifications: []
            },

            selectedComment: null,
            selectedProperty: 'Product.product',
            selectedProductId: null,
            selectedProductGroup: null,
            scrollingTable: false,
            scrollingTableHeight: 400,
            scrollingTableHeightMinimum: 135
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Product.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    groupable: false,
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.product'),
                    value: 'Product.product',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.designProfile'),
                    value: 'Product.design_profiles',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.species'),
                    value: 'Product.species',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.density'),
                    value: 'Product.densities',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.composition'),
                    value: 'Product.compositions',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.glue'),
                    value: 'Product.glues',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.surfaceEdges'),
                    value: 'Product.surfaces',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.finishing'),
                    value: 'Product.finishings',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: this.$t('message.grades'),
                    value: 'Product.grades',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.mc'),
                    value: 'Product.mcs',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.thickness'),
                    value: 'Product.thicknesses',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.width'),
                    value: 'Product.widths',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: this.$t('message.length'),
                    value: 'Product.lengths',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 15,
                    text: this.$t('message.originCertification'),
                    value: 'Product.origin_certifications',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 16,
                    text: this.$t('message.marketCertification'),
                    value: 'Product.market_certifications',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 17,
                    text: this.$t('message.comments'),
                    value: 'Product.comments',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                }
            ]
        }
    },
    methods: {
        capitalize,
        changeProductClosed() {
            this.changeProduct = false
            this.selectedProductId = null
            this.selectedProductGroup = null
            return true
        },
        copyProduct(productToCopyId) {
            api
                .put('/products/' + productToCopyId + '/copy')
                .then((response) => {
                    if(response.data.status == 'success'){
                        this.getProductList()
                    } else {
                        this.$toast.error(this.$t('message.errors.productNotCopied'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.productNotCopied'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
            return true;
        },
        async deleteProduct(productId, product) {
            if(await this.$root.$confirm(this.$t('message.delete') + ': ' +  product, this.$t('message.confirmations.continueProductCancelAction'), {color: 'orange'})){
                api
                    .delete('/products/' + productId)
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.$toast.success(this.$t('message.successes.productDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.getProductList()
                        } else {
                            this.$toast.error(this.$t('message.errors.productNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.productNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
            return true;
        },
        // async deletePropertyFromProduct(productId, product, property, propertyId) {
        //     if(await this.$root.$confirm(this.$t('message.deletePropertyFrom') + ': ' +  product, this.$t('message.confirmations.continuePropertyCancelAction'), {color: 'orange'})){
        //         api
        //             .delete('/products/' + productId + '/properties/' + property + '/' + propertyId)
        //             .then((response) => {
        //                 if(response.data.status == 'success') {
        //                     this.$toast.success(this.$t('message.successes.propertyDeleted'),
        //                         {
        //                             classes: ['icon-float-left'],
        //                             icon: 'check_circle_outline'
        //                         }
        //                     )
        //                     this.getProductList()
        //                 } else {
        //                     this.$toast.error(this.$t('message.errors.propertyNotDeleted'),
        //                         {
        //                             classes: ['icon-float-left'],
        //                             icon: 'error_outline'
        //                         }
        //                     )
        //                 }
        //             })
        //             .catch(() => {
        //                 this.$toast.error(this.$t('message.errors.propertyNotDeleted'),
        //                     {
        //                         classes: ['icon-float-left'],
        //                         icon: 'error_outline'
        //                     }
        //                 )
        //             })
        //     }
        //     return true;
        // },
        getHeader(textValue) {
            let headerText = null
            const header = this.headers.find(header => {
                return header.value == textValue[0]
            })
            if(header) headerText = header.text
            return headerText
        },
        getProductList() {
            if(this.supplierId) {
                this.loading.products = true
                api
                    .get('/products/suppliers/' + this.supplierId)
                    .then((response) => {
                        if (response.data.status == 'success') {
                            this.allProducts = response.data.data
                            const retObj = {
                                supplierName: response.data.supplierName
                            }
                            this.$emit('list-loaded', retObj);
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.products = false
                        if (this.loading.reload) this.loading.reload = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.products = false
                        if (this.loading.reload) this.loading.reload = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            return true;
        },
        getPropertyValue(property) {
            if(typeof property === 'string') {
                return property
            } else {
                if(typeof property == 'object' && typeof property[0] !== 'undefined') {
                    const value = property[0]['prop_value']
                    if (value) return value
                }
            }
            return 'na'
        },
        getProductsProperties() {
            return new Promise((resolve, reject) => {
                api
                    .get('/products/suppliers/' + this.supplierId + '/properties')
                    .then((response) => {
                        if(response.data.status == 'success' && response.data.data.hasOwnProperty('propertyOptions')){
                            const propertyOptions = response.data.data.propertyOptions
                            if(propertyOptions.hasOwnProperty('designs')) this.propertyOptions.designs = propertyOptions['designs']
                            if(propertyOptions.hasOwnProperty('species')) this.propertyOptions.species = propertyOptions['species']
                            if(propertyOptions.hasOwnProperty('densities')) this.propertyOptions.densities = propertyOptions['densities']
                            if(propertyOptions.hasOwnProperty('compositions')) this.propertyOptions.compositions = propertyOptions['compositions']
                            if(propertyOptions.hasOwnProperty('glues')) this.propertyOptions.glues = propertyOptions['glues']
                            if(propertyOptions.hasOwnProperty('surfaces')) this.propertyOptions.surfaces = propertyOptions['surfaces']
                            if(propertyOptions.hasOwnProperty('finishings')) this.propertyOptions.finishings = propertyOptions['finishings']
                            if(propertyOptions.hasOwnProperty('grades')) this.propertyOptions.grades = propertyOptions['grades']
                            if(propertyOptions.hasOwnProperty('mcs')) this.propertyOptions.mcs = propertyOptions['mcs']
                            if(propertyOptions.hasOwnProperty('thicknesses')) this.propertyOptions.thicknesses = propertyOptions['thicknesses']
                            if(propertyOptions.hasOwnProperty('widths')) this.propertyOptions.widths = propertyOptions['widths']
                            if(propertyOptions.hasOwnProperty('lengths')) this.propertyOptions.lengths = propertyOptions['lengths']
                            if(propertyOptions.hasOwnProperty('market_certifications')) this.propertyOptions.market_certifications = propertyOptions['market_certifications']
                            if(propertyOptions.hasOwnProperty('origin_certifications')) this.propertyOptions.origin_certifications = propertyOptions['origin_certifications']
                            resolve('done')
                        } else {
                            reject('error')
                        }
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        openAddComment(productId, productComment) {
            this.addComment = true
            this.selectedComment = productComment
            this.selectedProductId = productId
            return true;
        },
        openProductChanger(productId, productGroup) {
            this.changeProduct = true
            this.selectedProductId = productId
            this.selectedProductGroup = productGroup
            return true;
        },
        openProductSelector() {
            this.productSelector = true
            return true;
        },
        productAdded() {
            this.productSelectorKey += 1
            this.getProductList()
        },
        productSelectorClosed() {
            this.productSelector = false
            return true
        },
        productUpdated() {
            this.changeProductKey += 1
            this.getProductList()
        },
        removeProductPropertyOption(propertyOption) {
            this.$nextTick(function(){
                const productId = propertyOption.id
                const propertyName = propertyOption.property
                const idx = this.allProducts.findIndex(p => p.Product.id == productId)
                if(idx != -1){
                    const optionIndex = this.allProducts[idx]['Product'][propertyName].indexOf(propertyOption.item.id)
                    if(optionIndex >= 0) {
                        this.allProducts[idx]['Product'][propertyName].splice(optionIndex, 1)
                    }
                }
            })
        },
        updateProductProperty(property) {
            this.$nextTick(function(){
                const productId = property.id
                const propertyName = property.property
                const idx = this.allProducts.findIndex(p => p.Product.id == productId)
                if(idx != -1){
                    if(['comments','thicknesses','widths','lengths'].includes(propertyName)) this.allProducts[idx]['Product'][propertyName] = property.value
                    const product = _.cloneDeep(this.allProducts[idx]['Product'])
                    delete product.product
                    api
                        .put('/products/suppliers/' + productId, { product: product })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                if(response.data.clean == false) {
                                    this.$toast.success(this.$t('message.successes.productUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                }
                            } else {
                                if(isObject(response.data.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.data.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.productNotUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.productNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.propertyNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            })
        }
    },
    watch: {
        scrollingTable(value){
            localStorage.setItem('scrollingTable',value)
        }
    },
    created() {
        if(localStorage.getItem('scrollingTable') !== null){
            this.scrollingTable = localStorage.getItem('scrollingTable') == 'true' ? true : null
        }
    },
    mounted() {
        this.getProductsProperties()
            .then(() => {
                this.getProductList()
            })
            .catch(() => {
                this.$toast.clearQueue
                this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            })
    }
}
</script>

<style scoped>
.max-width-140px {
    width: 140px !important;
}
.max-width-90px {
    width: 90px !important;
}
.width-70px {
    width: 70px !important;
}
.width-200px {
    width: 200px !important;
}
.v-data-table {
    overflow-x: auto;
}
.v-data-table::v-deep td.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep .v-row-group__header td{
    font-size: 12px !important;
}
.v-chip__content {
    font-size: 0.75rem !important;
}
p {
    margin-bottom: unset !important;
}
.super-dense.bg-lightgrey {
    background-color: #F5F5F5;
}
.super-dense.v-text-field__slot input {
    text-align: right;
}
.super-dense::v-deep .v-input__control {
    min-height: 32px !important;
    background-color: #F5F5F5;
}
.super-dense::v-deep .v-input__slot {
    min-height: 32px !important;
    padding: 0 6px !important;
}
.super-dense::v-deep .v-text-field__slot {
    min-height: 32px !important;
}
.super-dense::v-deep .v-select__selections {
    min-height: 32px !important;
}
.super-dense::v-deep .v-input__append-inner {
    margin-top: 2px !important;
}
.super-dense::v-deep textarea {
    margin-top: 2px !important;
    line-height: 1.2;
    min-width: 100px;
}
.v-data-table td {
    padding: 0 5px !important;
}
.v-input__append-inner {
    margin-left: 0px !important;
}
</style>