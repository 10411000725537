var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"dense-inputs"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('message.productsList')))]),_c('v-btn',{staticClass:"ml-4",attrs:{"small":""},on:{"click":function($event){return _vm.openProductSelector()}}},[_vm._v(_vm._s(_vm.$t('message.addProduct')))]),_c('v-btn',{staticClass:"ml-3 mt-1",attrs:{"small":"","icon":"","loading":_vm.loading.reload},on:{"click":function($event){_vm.getProductList(); _vm.loading.reload = true}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-switch',{staticClass:"ml-4",staticStyle:{"margin-top":"3px !important"},attrs:{"label":_vm.$t('message.scrollingList'),"dense":"","hide-details":""},model:{value:(_vm.scrollingTable),callback:function ($$v) {_vm.scrollingTable=$$v},expression:"scrollingTable"}}),_c('v-spacer'),_c('ExportTableJson',{staticClass:"float-right",staticStyle:{"padding-top":"0px !important"},attrs:{"export-conditions":{},"export-data":_vm.allProducts,"export-fields":_vm.headers,"export-source":'supplier-product-table'}})],1)]),_c('div',{staticStyle:{"position":"relative"}},[_c('v-overlay',{attrs:{"value":_vm.loading.products,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"fixed-header":_vm.scrollingTable,"headers":_vm.headers,"height":_vm.scrollingTable ? (_vm.allProducts.length > 10 ? _vm.scrollingTableHeight : 100) : undefined,"items-per-page":-1,"items":_vm.allProducts,"group-by":_vm.defaultGroupByProperty,"dense":"","hide-default-footer":"","show-group-by":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var groupBy = ref.groupBy;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus'))]),_vm._v(" "+_vm._s(_vm.getHeader(groupBy) + ': ' + _vm.getPropertyValue(group))+" ")],1)])]}},{key:"item.Product.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openProductChanger(item.Product.id, item.Product.product)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"black lighten-1"}},[_vm._v("mdi-swap-horizontal-variant")]),_vm._v(_vm._s(_vm.$t('message.changeProduct')))],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.copyProduct(item.Product.id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"black lighten-1"}},[_vm._v("mdi-content-copy")]),_vm._v(_vm._s(_vm.$t('message.copyProduct')))],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteProduct(item.Product.id, item.Product.product)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteProduct')))],1)],1)],1)],1)]}},{key:"item.Product.design_profiles",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_sp_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.designs,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_sp_' + item.Product.id, id: item.Product.id, property: 'design_profiles', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'design_profiles',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.design_profiles),callback:function ($$v) {_vm.$set(item.Product, "design_profiles", $$v)},expression:"item.Product.design_profiles"}})],1)]}},{key:"item.Product.species",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_sp_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.species,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_sp_' + item.Product.id, id: item.Product.id, property: 'species', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'species',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.species),callback:function ($$v) {_vm.$set(item.Product, "species", $$v)},expression:"item.Product.species"}})],1)]}},{key:"item.Product.densities",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_de_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.densities,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_de_' + item.Product.id, id: item.Product.id, property: 'densities', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'densities',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.densities),callback:function ($$v) {_vm.$set(item.Product, "densities", $$v)},expression:"item.Product.densities"}})],1)]}},{key:"item.Product.compositions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_co_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.compositions,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_co_' + item.Product.id, id: item.Product.id, property: 'compositions', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'compositions',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.compositions),callback:function ($$v) {_vm.$set(item.Product, "compositions", $$v)},expression:"item.Product.compositions"}})],1)]}},{key:"item.Product.glues",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_gl_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.glues,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_gl_' + item.Product.id, id: item.Product.id, property: 'glues', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'glues',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.glues),callback:function ($$v) {_vm.$set(item.Product, "glues", $$v)},expression:"item.Product.glues"}})],1)]}},{key:"item.Product.surfaces",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_su_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.surfaces,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_su_' + item.Product.id, id: item.Product.id, property: 'surfaces', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'surfaces',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.surfaces),callback:function ($$v) {_vm.$set(item.Product, "surfaces", $$v)},expression:"item.Product.surfaces"}})],1)]}},{key:"item.Product.finishings",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_fi_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.finishings,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_fi_' + item.Product.id, id: item.Product.id, property: 'finishings', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'finishings',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.finishings),callback:function ($$v) {_vm.$set(item.Product, "finishings", $$v)},expression:"item.Product.finishings"}})],1)]}},{key:"item.Product.grades",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_gr_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.grades,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_gr_' + item.Product.id, id: item.Product.id, property: 'grades', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'grades',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.grades),callback:function ($$v) {_vm.$set(item.Product, "grades", $$v)},expression:"item.Product.grades"}})],1)]}},{key:"item.Product.origin_certifications",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-90px"},[_c('v-autocomplete',{ref:'select_oc_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.origin_certifications,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_oc_' + item.Product.id, id: item.Product.id, property: 'origin_certifications', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'origin_certifications',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.origin_certifications),callback:function ($$v) {_vm.$set(item.Product, "origin_certifications", $$v)},expression:"item.Product.origin_certifications"}})],1)]}},{key:"item.Product.market_certifications",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-90px"},[_c('v-autocomplete',{ref:'select_mc_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.market_certifications,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_mc_' + item.Product.id, id: item.Product.id, property: 'market_certifications', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'market_certifications',id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.market_certifications),callback:function ($$v) {_vm.$set(item.Product, "market_certifications", $$v)},expression:"item.Product.market_certifications"}})],1)]}},{key:"item.Product.mcs",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-autocomplete',{ref:'select_mo_' + item.Product.id,staticClass:"mb-1 super-dense pa-0",attrs:{"items":_vm.propertyOptions.mcs,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","item-text":"title","item-value":"id","multiple":"","outlined":""},on:{"blur":function($event){return _vm.updateProductProperty({ref: 'select_mo_' + item.Product.id, id: item.Product.id, property: 'mcs', value: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeProductPropertyOption({item: data.item, property: 'mcs', id: item.Product.id})}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(item.Product.mcs),callback:function ($$v) {_vm.$set(item.Product, "mcs", $$v)},expression:"item.Product.mcs"}})],1)]}},{key:"item.Product.thicknesses",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-90px"},[_c('v-lazy',[_c('v-textarea',{staticClass:"super-dense pa-0 mb-1",attrs:{"value":item.Product.thicknesses,"auto-grow":"","clearable":"","dense":"","hide-details":"auto","outlined":"","rows":"1"},on:{"change":function($event){return _vm.updateProductProperty({id: item.Product.id, property: 'thicknesses', value: $event})},"click:clear":function($event){item.Product.thicknesses = null}}})],1)],1)]}},{key:"item.Product.widths",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-90px"},[_c('v-lazy',[_c('v-textarea',{staticClass:"super-dense pa-0 mb-1",attrs:{"value":item.Product.widths,"auto-grow":"","clearable":"","dense":"","hide-details":"auto","outlined":"","rows":"1"},on:{"change":function($event){return _vm.updateProductProperty({id: item.Product.id, property: 'widths', value: $event})},"click:clear":function($event){item.Product.widths = null}}})],1)],1)]}},{key:"item.Product.lengths",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-90px"},[_c('v-lazy',[_c('v-textarea',{staticClass:"super-dense pa-0 mb-1",attrs:{"value":item.Product.lengths,"auto-grow":"","clearable":"","dense":"","hide-details":"auto","outlined":"","rows":"1"},on:{"change":function($event){return _vm.updateProductProperty({id: item.Product.id, property: 'lengths', value: $event})},"click:clear":function($event){item.Product.lengths = null}}})],1)],1)]}},{key:"item.Product.comments",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start fill-height max-width-140px"},[_c('v-lazy',[_c('v-textarea',{staticClass:"super-dense pa-0 mb-1",attrs:{"value":item.Product.comments,"auto-grow":"","clearable":"","dense":"","hide-details":"auto","outlined":"","rows":"1"},on:{"change":function($event){return _vm.updateProductProperty({id: item.Product.id, property: 'comments', value: $event})},"click:clear":function($event){item.Product.comments = null}}})],1)],1)]}}])})],1),_c('ProductSelector',{key:_vm.productSelectorKey,attrs:{"customer-id":null,"supplier-id":_vm.supplierId,"open-dialog":_vm.productSelector},on:{"update:openDialog":function($event){_vm.productSelector=$event},"update:open-dialog":function($event){_vm.productSelector=$event},"product-added":_vm.productAdded,"dialog-closed":_vm.productSelectorClosed}}),_c('ChangeProduct',{attrs:{"customer-id":null,"supplier-id":_vm.supplierId,"product-id":_vm.selectedProductId,"current-product":_vm.selectedProductGroup,"open-dialog":_vm.changeProduct},on:{"update:openDialog":function($event){_vm.changeProduct=$event},"update:open-dialog":function($event){_vm.changeProduct=$event},"product-updated":_vm.productUpdated,"dialog-closed":_vm.changeProductClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }